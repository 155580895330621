// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../usr/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../usr/lib/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../../../../usr/lib/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./edit.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./edit-hover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat scroll 98% 3px rgba(0,0,0,0);cursor:pointer;padding:3px 26px 4px 4px}.editable:hover{background:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat scroll 98% 3px #f2f2f2}`, "",{"version":3,"sources":["webpack://./webpack/assets/javascripts/react_app/components/SettingsTable/components/SettingCell.scss"],"names":[],"mappings":"AAAA,UACE,yFAAA,CACA,cAAA,CACA,wBAAA,CAEA,gBACE,mFAAA","sourcesContent":[".editable {\n  background: url('./edit.png') no-repeat scroll 98% 3px transparent;\n  cursor: pointer;\n  padding: 3px 26px 4px 4px;\n\n  &:hover {\n    background: url('./edit-hover.png') no-repeat scroll 98% 3px #f2f2f2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
