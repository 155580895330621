export const VMWARE_CLUSTER_CHANGE = 'VMWARE_CLUSTER_CHANGE';
export const STORAGE_VMWARE_INIT = 'STORAGE_VMWARE_INIT';
export const STORAGE_VMWARE_ADD_CONTROLLER = 'STORAGE_VMWARE_ADD_CONTROLLER';
export const STORAGE_VMWARE_ADD_DISK = 'STORAGE_VMWARE_ADD_DISK';
export const STORAGE_VMWARE_REMOVE_CONTROLLER =
  'STORAGE_VMWARE_REMOVE_CONTROLLER';
export const STORAGE_VMWARE_UPDATE_CONTROLLER =
  'STORAGE_VMWARE_UPDATE_CONTROLLER';
export const STORAGE_VMWARE_REMOVE_DISK = 'STORAGE_VMWARE_REMOVE_DISK';
export const STORAGE_VMWARE_UPDATE_DISK = 'STORAGE_VMWARE_UPDATE_DISK';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const STORAGE_VMWARE_DATASTORES = 'STORAGE_VMWARE_DATASTORES';
export const STORAGE_VMWARE_DATASTORES_REQUEST =
  'STORAGE_VMWARE_DATASTORES_REQUEST';
export const STORAGE_VMWARE_DATASTORES_SUCCESS =
  'STORAGE_VMWARE_DATASTORES_SUCCESS';
export const STORAGE_VMWARE_DATASTORES_FAILURE =
  'STORAGE_VMWARE_DATASTORES_FAILURE';
export const STORAGE_VMWARE_STORAGEPODS = 'STORAGE_VMWARE_STORAGEPODS';
export const STORAGE_VMWARE_STORAGEPODS_REQUEST =
  'STORAGE_VMWARE_STORAGEPODS_REQUEST';
export const STORAGE_VMWARE_STORAGEPODS_SUCCESS =
  'STORAGE_VMWARE_STORAGEPODS_SUCCESS';
export const STORAGE_VMWARE_STORAGEPODS_FAILURE =
  'STORAGE_VMWARE_STORAGEPODS_FAILURE';
export const NOTIFICATIONS_TOGGLE_DRAWER = 'NOTIFICATIONS_TOGGLE_DRAWER';
export const NOTIFICATIONS_SET_EXPANDED_GROUP =
  'NOTIFICATIONS_SET_EXPANDED_GROUP';
export const NOTIFICATIONS_MARK_AS_READ = 'NOTIFICATIONS_MARK_AS_READ';
export const NOTIFICATIONS_MARK_GROUP_AS_READ =
  'NOTIFICATIONS_MARK_GROUP_AS_READ';
export const NOTIFICATIONS_MARK_AS_CLEAR = 'NOTIFICATIONS_MARK_AS_CLEAR';
export const NOTIFICATIONS_MARK_GROUP_AS_CLEARED =
  'NOTIFICATIONS_MARK_GROUP_AS_CLEARED';
export const NOTIFICATIONS_LINK_CLICKED = 'NOTIFICATIONS_LINK_CLICKED';
export const PASSWORD_STRENGTH_PASSWORD_CHANGED =
  'PASSWORD_STRENGTH_PASSWORD_CHANGED';
export const PASSWORD_STRENGTH_PASSWORD_MATCHED =
  'PASSWORD_STRENGTH_PASSWORD_MATCHED';
