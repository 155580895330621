export const EDITOR_INITIALIZE = 'EDITOR_INITIALIZE';
export const EDITOR_IMPORT_FILE = 'EDITOR_IMPORT_FILE';
export const EDITOR_REVERT_CHANGES = 'EDITOR_REVERT_CHANGES';
export const EDITOR_EXEC_PREVIEW = 'EDITOR_EXEC_PREVIEW';
export const EDITOR_MODAL_TOGGLE = 'EDITOR_MODAL_TOGGLE';
export const EDITOR_CHANGE_DIFF_VIEW = 'EDITOR_CHANGE_DIFF_VIEW';
export const EDITOR_CHANGE_VALUE = 'EDITOR_CHANGE_VALUE';
export const EDITOR_SHOW_ERROR = 'EDITOR_SHOW_ERROR';
export const EDITOR_DISMISS_ERROR = 'EDITOR_DISMISS_ERROR';
export const EDITOR_CHANGE_TAB = 'EDITOR_CHANGE_TAB';
export const EDITOR_TOGGLE_MASK = 'EDITOR_TOGGLE_MASK';
export const EDITOR_CHANGE_SETTING = 'EDITOR_CHANGE_SETTING';
export const EDITOR_TOGGLE_RENDER_VIEW = 'EDITOR_TOGGLE_RENDER_VIEW';
export const EDITOR_SHOW_LOADING = 'EDITOR_SHOW_LOADING';
export const EDITOR_HIDE_LOADING = 'EDITOR_HIDE_LOADING';
export const EDITOR_FETCH_HOST_PENDING = 'EDITOR_FETCH_HOST_PENDING';
export const EDITOR_FETCH_HOST_RESOLVED = 'EDITOR_FETCH_HOST_RESOLVED';
export const EDITOR_HOST_SELECT_TOGGLE = 'EDITOR_HOST_SELECT_TOGGLE';
export const EDITOR_HOST_SELECT_CLEAR = 'EDITOR_HOST_SELECT_CLEAR';
export const EDITOR_HOST_SELECT_RESET = 'EDITOR_HOST_SELECT_RESET';
export const EDITOR_HOST_INITIAL_FETCH = 'EDITOR_HOST_INITIAL_FETCH';
export const EDITOR_CHANGE_KIND = 'EDITOR_CHANGE_KIND';

export const EDITOR_HOSTS_URL = '/hosts/preview_host_collection.json';
export const EDITOR_HOST_ARR = 'hosts';
export const EDITOR_HOST_FILTERED_ARR = 'filteredHosts';
export const EDITOR_KEYBINDINGS = ['Default', 'Emacs', 'Vim'];
export const EDITOR_THEMES = ['Github', 'Monokai'];
export const EDITOR_MODES = [
  'Text',
  'Json',
  'Ruby',
  'Html_ruby',
  'Sh',
  'Xml',
  'Yaml',
];
