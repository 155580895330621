export const BREADCRUMB_BAR_OPEN_SWITCHER = 'BREADCRUMB_BAR_OPEN_SWITCHER';
export const BREADCRUMB_BAR_CLOSE_SWITCHER = 'BREADCRUMB_BAR_CLOSE_SWITCHER';
export const BREADCRUMB_BAR_RESOURCES_REQUEST =
  'BREADCRUMB_BAR_RESOURCES_REQUEST';
export const BREADCRUMB_BAR_RESOURCES_SUCCESS =
  'BREADCRUMB_BAR_RESOURCES_SUCCESS';
export const BREADCRUMB_BAR_RESOURCES_FAILURE =
  'BREADCRUMB_BAR_RESOURCES_FAILURE';
export const BREADCRUMB_BAR_CLEAR_SEARCH = 'BREADCRUMB_BAR_DELETE_SEARCH';
export const BREADCRUMB_BAR_UPDATE_TITLE = 'BREADCRUMB_BAR_UPDATE_TITLE';

export const BREADCRUMB_SWITCHER_PER_PAGE = 10;